import React, { FC } from 'react';
import Button from '../../atoms/button/Button';
import { ConfigForm } from '../../pages/augur/utils/config.form';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInputArea } from '../../atoms/react-hook-form-input-elements/text-input-area/TextInputArea';
import { findElementMeta } from '../augur-layout-elements/common/utils';
import styles from './styles.module.scss';
import { TransformationWrapper } from '../../pages/augur/EditAugur';
import { AugurReport, isAugurSettingsElement } from '../../pages/augur/type';

export type Props = {
  selectedElementId: string;
  sampleReport: AugurReport;
  onBack: () => void;
};

const EditConfigurationPage: FC<Props> = ({
  selectedElementId,
  sampleReport,
  onBack,
}) => {
  const { control, getValues, setValue } = useFormContext<ConfigForm>();

  const element = getValues(`elements.${selectedElementId}`);
  const elementMeta = findElementMeta(element.type, element.version);

  return (
    <div className={styles.editConfigContainer}>
      <div className={styles.header}>
        <h2>Edit Configuration</h2>
      </div>

      <div className={styles.body}>
        <div className={styles.leftPanel}>
          <div className={styles.element}>
            <TransformationWrapper
              elementId={selectedElementId}
              sampleReport={sampleReport}
              // EditMode (no input allowed) is only active for settings element,
              // since settings element should not be configured
              editMode={isAugurSettingsElement(element)}
            />
          </div>
        </div>
        <div className={styles.rightPanel}>
          {elementMeta.configFormComponent ? (
            React.createElement(elementMeta.configFormComponent, {
              defaultValues: JSON.parse(
                getValues(`elements.${selectedElementId}.config`)
              ),
              onChange: (updatedConfig: string) => {
                setValue(
                  `elements.${selectedElementId}.config`,
                  updatedConfig,
                  {
                    shouldValidate: true,
                  }
                );
              },
              elements: getValues('elements'),
            })
          ) : (
            // If the configFormComponent does not exist, display the default JSON editor
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
            >
              <Controller
                name={`elements.${selectedElementId}.config`}
                control={control}
                render={({ field, fieldState }) => {
                  const { ref, ...rest } = field;
                  return (
                    <TextInputArea
                      label={'Configuration'}
                      placeholder={'{\n\n}'}
                      {...rest}
                      {...fieldState}
                      isTouched={true}
                      inputRef={ref}
                      error={fieldState.error?.message}
                      amountRows={10}
                    />
                  );
                }}
              />
              <TextInputArea
                label={'Configuration Type'}
                disabled={true}
                value={elementMeta?.configurationType}
                amountRows={10}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          buttonColor='primary'
          withLink={false}
          buttonLabelDefault='Close'
          onClick={onBack}
        />
      </div>
    </div>
  );
};

export default EditConfigurationPage;
