import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LuFileJson } from 'react-icons/lu';

import styles from './styles.module.scss';
import { TextInputArea } from '../../atoms/react-hook-form-input-elements/text-input-area/TextInputArea';
import { IntlTextInputLine } from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import Modal from '../../organisms/modal/Modal';
import {
  AugurReport,
  AugurSettings,
  isAugurReportsType,
  isAugurSettingsType,
} from '../../pages/augur/type';
import { ConfigForm } from '../../pages/augur/utils/config.form';
import { findElementMeta } from '../augur-layout-elements/common/utils';
import { AugurReportElementMeta } from '../augur-layout-elements/report-elements/types/meta';
import { AugurSettingsElementMeta } from '../augur-layout-elements/settings-elements/types/meta';
import Button from '../../atoms/button/Button';
import { FiInfo } from 'react-icons/fi';

/*
 * TODO-CM: There is currently a bug when deleting elements that are selected (ElementConfigurationPage is opened):
 *  When deleting such an element all element form fields are unregistered and subsequently its values are deleted.
 *  However, because the Controllers of the ElementConfigurationPage still exist at this point, the values get repopulated by RHF.
 *  This is seemingly independent from the render cycle.
 *  This is currently not a problem because all elements are directly tied to their IDs in the form state but it creates and invalid form state which might create problems in the future.
 *  A likely solution to this problem is a custom implementation that does not rely on RHF's Controllers.
 */

export type Props = {
  selectedElementId: string;
  sampleReport?: AugurReport['reportData'];
  sampleSettings?: AugurSettings['settingsData'];
  onEditConfigurationButton: () => void;
};

const ElementConfigurationPage = ({
  selectedElementId,
  sampleReport,
  sampleSettings,
  onEditConfigurationButton,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const { control, watch, getFieldState } = useFormContext<ConfigForm>();

  const type = watch(`elements.${selectedElementId}.type`);
  const version = watch(`elements.${selectedElementId}.version`);
  const uuid = watch(`elements.${selectedElementId}.uuid`);

  const configErrorState = getFieldState(`elements.${selectedElementId}.config`, control._formState);

  const elementMeta = findElementMeta(type, version);

  function renderConfigurationSection() {
    return (
      <>
        <div className={styles.elementTypeContainer}>
          <span>{elementMeta.name.defaultMessage}</span>
          <FiInfo 
            className={styles.infoIcon} 
            title={`Copy UUID: ${uuid}`}
            onClick={() => navigator.clipboard.writeText(uuid).then(() => {})}
          />
        </div>
        <div className={styles.textInputArea}>
          <Controller
            name={`elements.${selectedElementId}.title`}
            control={control}
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlTextInputLine
                  label={'Title'}
                  placeholder={'Please enter a title'}
                  {...rest}
                  {...fieldState}
                  // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                  isTouched={true}
                  inputRef={ref}
                  error={fieldState.error?.message}
                />
              );
            }}
          />
        </div>
        <div className={styles.textInputArea}>
          <Controller
            name={`elements.${selectedElementId}.description`}
            control={control}
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlTextInputLine
                  label={'Description'}
                  placeholder={'Please enter a description'}
                  {...rest}
                  {...fieldState}
                  // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                  isTouched={true}
                  inputRef={ref}
                  error={fieldState.error?.message}
                />
              );
            }}
          />
        </div>

        <div className={styles.textInputArea}>
          <Button
            buttonColor={'primary'}
            withLink={false}
            buttonLabelDefault={'Edit Configuration'}
            onClick={onEditConfigurationButton}
            disabled={elementMeta.configurationType === `{}`}
          />
        </div>

        {configErrorState.error?.message && (
          <div className={styles.errorMessage}>
            {configErrorState.error.message}
          </div>
        )}

        <hr />
      </>
    );
  }

  function renderSettingsSection() {
    return (
      <>
        {showModal && (
          <Modal
            show={showModal}
            hideModal={() => setShowModal(false)}
            headline={{
              id: 'no-id',
              defaultMessage: 'Current Augur Settings file',
            }}
          >
            <div className={styles.modalTextArea}>
              <TextInputArea
                label={'Augur Settings'}
                amountRows={24}
                disabled={true}
                value={
                  JSON.stringify(sampleSettings, null, 2) ??
                  'No settings available.'
                }
              />
            </div>
          </Modal>
        )}

        <div>
          <Controller
            name={`elements.${selectedElementId}.settingsKey`}
            control={control}
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlTextInputLine
                  label={'Augur Settings Key'}
                  placeholder={'Type an identifier here'}
                  {...rest}
                  {...fieldState}
                  // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                  isTouched={true}
                  inputRef={ref}
                  error={fieldState.error?.message}
                />
              );
            }}
          />
          <div
            className={styles.showFileContainer}
            onClick={() => setShowModal(true)}
          >
            <LuFileJson className={styles.showFileIcon} size={13} />
            <span className={styles.showFileText}>
              Show current Augur Settings file
            </span>
          </div>
        </div>

        <TextInputArea
          label={'Augur Settings Type'}
          isCollapsible={true}
          initialCollapsed={false}
          disabled={true}
          value={(elementMeta as AugurSettingsElementMeta).augurSettingsType}
        />
        <Controller
          name={`elements.${selectedElementId}.defaultAugurSettings`}
          control={control}
          render={({ field, fieldState }) => {
            const { ref, ...rest } = field; // extract ref to pass as inputRef
            return (
              <TextInputArea
                label={'Default Augur Settings'}
                placeholder={'Please enter a valid default json object'}
                isCollapsible={true}
                initialCollapsed={false}
                {...rest}
                {...fieldState}
                // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                isTouched={true}
                inputRef={ref}
                error={fieldState.error?.message}
              />
            );
          }}
        />

        <Controller
          name={`elements.${selectedElementId}.validationSchema`}
          control={control}
          render={({ field, fieldState }) => {
            const { ref, ...rest } = field; // extract ref to pass as inputRef
            return (
              <TextInputArea
                label={'Validation Schema'}
                placeholder={''}
                isCollapsible={true}
                initialCollapsed={false}
                description={
                  'Please use JSONSchema here. For further information visit https://json-schema.org/'
                }
                showInfo={true}
                {...rest}
                {...fieldState}
                // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                isTouched={true}
                inputRef={ref}
                error={fieldState.error?.message}
              />
            );
          }}
        />
      </>
    );
  }

  function renderReportSection() {
    const reportKey = watch(`elements.${selectedElementId}.reportKey`);
    const reportData = sampleReport?.[reportKey];

    return (
      <>
        {showModal && (
          <Modal
            show={showModal}
            hideModal={() => setShowModal(false)}
            headline={{
              id: 'no-id',
              defaultMessage: 'Current Augur Report file',
            }}
          >
            <div className={styles.modalTextArea}>
              <TextInputArea
                amountRows={24}
                label={'Augur Report'}
                disabled={true}
                value={
                  JSON.stringify(sampleReport, null, 2) ??
                  'No report available.'
                }
              />
            </div>
          </Modal>
        )}
        <div>
          <Controller
            name={`elements.${selectedElementId}.reportKey`}
            control={control}
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlTextInputLine
                  label={'Report Key'}
                  placeholder={'Type an identifier here'}
                  {...rest}
                  {...fieldState}
                  // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                  isTouched={true}
                  inputRef={ref}
                  error={fieldState.error?.message}
                />
              );
            }}
          />
          <div
            className={styles.showFileContainer}
            onClick={() => setShowModal(true)}
          >
            <LuFileJson className={styles.showFileIcon} size={13} />
            <span className={styles.showFileText}>
              Show current Augur Report file
            </span>
          </div>
        </div>

        <TextInputArea
          label={'Report Data'}
          isCollapsible={true}
          initialCollapsed={false}
          disabled={true}
          value={
            JSON.stringify(reportData, null, 2) ??
            'Invalid report key, showing default data.'
          }
        />
        <TextInputArea
          label={'Report Data Type'}
          isCollapsible={true}
          initialCollapsed={false}
          disabled={true}
          value={(elementMeta as AugurReportElementMeta).reportDataType}
        />
      </>
    );
  }

  return (
    <div className={styles.configPaneContentContainer}>
      {renderConfigurationSection()}
      {isAugurReportsType(type)
        ? renderReportSection()
        : isAugurSettingsType(type)
          ? renderSettingsSection()
          : null}
    </div>
  );
};

export default ElementConfigurationPage;
