import { ToBeRefined } from '../types/todo_type';
import { MessageDescriptor } from 'react-intl';

export const weekDaysMap = {
  0: 'common.scheduleSelect.sunday',
  1: 'common.scheduleSelect.monday',
  2: 'common.scheduleSelect.tuesday',
  3: 'common.scheduleSelect.wednesday',
  4: 'common.scheduleSelect.thursday',
  5: 'common.scheduleSelect.friday',
  6: 'common.scheduleSelect.saturday',
};

export const weekDays = Object.values(weekDaysMap);

export const monthDaysMap = {
  1: 'common.scheduleSelect.1st',
  2: 'common.scheduleSelect.2nd',
  3: 'common.scheduleSelect.3rd',
  4: 'common.scheduleSelect.4th',
  5: 'common.scheduleSelect.5th',
  6: 'common.scheduleSelect.6th',
  7: 'common.scheduleSelect.7th',
  8: 'common.scheduleSelect.8th',
  9: 'common.scheduleSelect.9th',
  10: 'common.scheduleSelect.10th',
  11: 'common.scheduleSelect.11th',
  12: 'common.scheduleSelect.12th',
  13: 'common.scheduleSelect.13th',
  14: 'common.scheduleSelect.14th',
  15: 'common.scheduleSelect.15th',
  16: 'common.scheduleSelect.16th',
  17: 'common.scheduleSelect.17th',
  18: 'common.scheduleSelect.18th',
  19: 'common.scheduleSelect.19th',
  20: 'common.scheduleSelect.20th',
  21: 'common.scheduleSelect.21th',
  22: 'common.scheduleSelect.22th',
  23: 'common.scheduleSelect.23th',
  24: 'common.scheduleSelect.24th',
  25: 'common.scheduleSelect.25th',
  26: 'common.scheduleSelect.26th',
  27: 'common.scheduleSelect.27th',
  28: 'common.scheduleSelect.28th',
  29: 'common.scheduleSelect.29th',
  30: 'common.scheduleSelect.30th',
  31: 'common.scheduleSelect.31st',
};
export const monthDays = Object.values(monthDaysMap);

export type Period = {
  id: 'hourly' | 'daily' | 'weekly' | 'monthly';
  label: string;
  hasValue: boolean;
  labelValue?: MessageDescriptor;
  hasOptions: boolean;
  labelOptions?: MessageDescriptor;
  hasTime: boolean;
  labelTime?: MessageDescriptor;
  valueOptions?: { type: 'number'; min: number; max: number; step: number };
  options?: { id: number; label: string }[];
  optionsPre?: string;
  valuePre?: string;
  valuePost?: string;
  timePre?: string;
};

export const schedulePeriods: Period[] = [
  {
    id: 'hourly',
    label: 'common.scheduleSelect.hourly',
    hasValue: true,
    labelValue: {
      id: 'no-id',
      defaultMessage: 'Minute of Hour',
    },
    hasOptions: false,
    hasTime: false,
    valueOptions: {
      type: 'number',
      min: 0,
      max: 59,
      step: 1,
    },
    valuePre: 'at',
    valuePost: 'minutes of the hour',
  },
  {
    id: 'daily',
    label: 'common.scheduleSelect.daily',
    hasValue: false,
    hasOptions: false,
    hasTime: true,
    labelTime: {
      id: 'no-id',
      defaultMessage: 'Time of Day',
    },
    timePre: 'at',
  },
  {
    id: 'weekly',
    label: 'common.scheduleSelect.weekly',
    hasValue: false,
    hasOptions: true,
    labelOptions: {
      id: 'no-id',
      defaultMessage: 'Day of Week',
    },
    hasTime: true,
    labelTime: {
      id: 'no-id',
      defaultMessage: 'Time of Day',
    },
    options: weekDays.map((label, id) => ({ id, label })),
    optionsPre: 'on',
    timePre: 'at',
  },
  {
    id: 'monthly',
    label: 'common.scheduleSelect.monthly',
    hasValue: false,
    hasOptions: true,
    labelOptions: {
      id: 'no-id',
      defaultMessage: 'Day of Month',
    },
    hasTime: true,
    labelTime: {
      id: 'no-id',
      defaultMessage: 'Time of Day',
    },
    options: monthDays.map((label, id) => ({ id, label })),
    optionsPre: 'at',
    timePre: 'at',
  },
];

export const schedulePeriodsGrouped = schedulePeriods.reduce(
  (obj: Record<string, unknown>, period): ToBeRefined => {
    obj[period.id] = period; // eslint-disable-line
    return obj;
  },
  {}
);
