import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ConfigCampaignOptimizationCommunicationsConfig } from './type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import {
  ElementOptionComponent,
  ElementOptionType,
} from '../../common/ElementOptionComponent';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';

type CampaignOptimizationCommunicationsConfigFormProps = {
  defaultValues?: ConfigCampaignOptimizationCommunicationsConfig;
  onChange: (updatedConfig: string) => void;
  elements?: Record<string, ConfigPageElementForm>;
};

const CampaignOptimizationCommunicationsConfigFormComponent: FC<
  CampaignOptimizationCommunicationsConfigFormProps
> = ({ defaultValues, onChange, elements = {} }) => {
  const { control, getValues } =
    useForm<ConfigCampaignOptimizationCommunicationsConfig>({ defaultValues });

  const updateConfig = () => {
    const config = getValues();
    onChange(JSON.stringify(config));
  };

  const channelOptions: ElementOptionType[] = Object.values(elements)
    .filter(
      (element) =>
        element.type === SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CHANNELS
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  const propensityOptions: ElementOptionType[] = Object.values(elements)
    .filter(
      (element) =>
        element.type ===
        SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_PROPENSITIES
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <Controller
        name='channels.elementUuid'
        control={control}
        render={({ field }) => (
          <DropdownSelectInput<ElementOptionType, false>
            onChange={(option) => {
              field.onChange(option?.uuid);
              updateConfig();
            }}
            value={channelOptions.find((option) => option.uuid === field.value)}
            label='Channels'
            placeholder='Select Channels Element'
            options={channelOptions}
            customComponents={{ Option: ElementOptionComponent }}
          />
        )}
      />
      <Controller
        name='propensities.elementUuid'
        control={control}
        render={({ field }) => (
          <DropdownSelectInput<ElementOptionType, false>
            onChange={(option) => {
              field.onChange(option?.uuid);
              updateConfig();
            }}
            value={propensityOptions.find(
              (option) => option.uuid === field.value
            )}
            label='Propensities'
            placeholder='Select Propensities Element'
            options={propensityOptions}
            customComponents={{ Option: ElementOptionComponent }}
          />
        )}
      />
    </div>
  );
};

export default CampaignOptimizationCommunicationsConfigFormComponent;
