import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ConfigDropdownSelectCFEConfig, OptionsType } from './type';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import Table, { RenderColumn } from '../../../../table/Table';
import { FiPlus } from 'react-icons/fi';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import {
  ElementOptionComponent,
  ElementOptionType,
} from '../../common/ElementOptionComponent';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';

type DropdownSelectCFEConfigFormComponentProps = {
  defaultValues?: ConfigDropdownSelectCFEConfig;
  onChange: (updatedConfig: string) => void;
  elements?: Record<string, ConfigPageElementForm>;
};

type OptionsTypeWithIndex = OptionsType & { index: number };

const DropdownSelectCFEConfigFormComponent: FC<
  DropdownSelectCFEConfigFormComponentProps
> = ({ defaultValues, onChange, elements = {} }) => {
  const { control, getValues, setValue } =
    useForm<ConfigDropdownSelectCFEConfig>({ defaultValues });

  const updateConfig = () => {
    const config = getValues();
    onChange(JSON.stringify(config));
  };

  const options: ElementOptionType[] = Object.values(elements).map(
    (element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    })
  );

  const dataWithIndex =
    defaultValues?.options.source === 'hard-coded'
      ? defaultValues?.options.value.map((option, index) => ({
          ...option,
          index,
        }))
      : [];

  const renderColumns: RenderColumn<
    OptionsTypeWithIndex,
    keyof OptionsTypeWithIndex
  >[] = [
    {
      key: 'label',
      renderHeader: () => <span>Label</span>,
      width: '25%',
      // @ts-ignore
      renderCell: (label, row) => (
        <Controller
          name={`options.value.${row.index}.label`}
          control={control}
          render={({ field }) => (
            <IntlTextInputLine
              label='Label'
              placeholder='Label for Option 1'
              {...field}
              onChange={(e) => {
                field.onChange(e);
                updateConfig();
              }}
            />
          )}
        />
      ),
    },
    {
      key: 'value',
      renderHeader: () => <span>Value</span>,
      width: '25%',
      // @ts-ignore
      renderCell: (label, row) => (
        <Controller
          name={`options.value.${row.index}.value`}
          control={control}
          render={({ field }) => (
            <IntlTextInputLine
              label='Value'
              placeholder='Value for Option 1'
              {...field}
              onChange={(e) => {
                field.onChange(e);
                updateConfig();
              }}
            />
          )}
        />
      ),
    },
    {
      key: 'dependants',
      renderHeader: () => <span>Dependants</span>,
      // @ts-ignore
      renderCell: (label, row) => (
        <Controller
          name={`options.value.${row.index}.dependants`}
          control={control}
          render={({ field }) => (
            <DropdownSelectInput<ElementOptionType, true>
              options={options}
              label='Dependants'
              placeholder='Select Dependants'
              onChange={(options) => {
                const updatedUUIDs = options.map((option) => option.uuid);
                field.onChange(updatedUUIDs);
                updateConfig();
              }}
              value={options.filter((option) =>
                field.value?.includes(option.uuid)
              )}
              customComponents={{ Option: ElementOptionComponent }}
              isMulti
              appearance={'multiLines'}
            />
          )}
        />
      ),
    },
  ];

  const addNewRow = () => {
    const currentOptions = getValues('options.value');
    const newOption = {
      label: '',
      value: '',
      dependants: [],
    };
    setValue('options.value', [...currentOptions, newOption]);
    updateConfig();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <Controller
        name='placeholder.value'
        control={control}
        render={({ field }) => (
          <IntlTextInputLine
            label='Placeholder'
            placeholder='placeholder'
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateConfig();
            }}
          />
        )}
      />

      <h3>Options</h3>

      <div className={commonStyles.optComponent}>
        <div className={commonStyles.tableContainer}>
          <Table
            data={dataWithIndex}
            renderColumns={renderColumns}
            showHeader={false}
            removableRows={true}
            verticalAlignMiddle
            roundedBorder
            onRemoveRow={(removedRow) => {
              const updatedOptions = getValues('options.value').filter(
                (_, index) => index !== removedRow.index
              );
              setValue('options.value', updatedOptions);
              updateConfig();
            }}
          />
        </div>
        <div className={commonStyles.AddIconContainer}>
          <div className={commonStyles.AddIcon} onClick={() => addNewRow()}>
            <FiPlus size={18} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownSelectCFEConfigFormComponent;
