import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TupleListTable, { TableRow } from '../../../../tuple-list-table/TupleListTable';
import { ConfigPieChartConfig, } from './type';


type PieChartConfigFormProps = {
  defaultValues?: ConfigPieChartConfig;
  onChange: (updatedConfig: string) => void;
};

const PieChartConfigFormComponent: FC<PieChartConfigFormProps> = ({
  defaultValues,
  onChange,
}) => {
  const { control, getValues } = useForm<ConfigPieChartConfig>({ defaultValues });

  const updateConfig = () => {
    const config = getValues();
    onChange(JSON.stringify(config));
  };

  const prepareTableData = (legendEntries: { color: string; label: string }[]): TableRow[] => {
    return legendEntries.map((entry, index) => ({
      id: `legend-${index}`,
      value1: entry.label,
      value2: entry.color,
    }));
  };

  return (
    <Controller
      name="legend.value"
      control={control}
      render={({ field }) => (
        <TupleListTable
          label1="Label"
          placeholder1="Enter legend label"
          label2="Color"
          placeholder2="Enter legend color"
          data={prepareTableData(field.value || [])}
          onChange={(updatedRows) => {
            const updatedLegend = updatedRows.map((row) => ({
              label: row.value1 || '',
              color: row.value2 || '',
            }));
            field.onChange(updatedLegend);
            updateConfig();
          }}
        />
      )}
    />
  );
};

export default PieChartConfigFormComponent;