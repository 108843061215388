import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { ConfigLineChartConfig } from './type';

type LineChartConfigFormProps = {
  defaultValues?: ConfigLineChartConfig;
  onChange: (updatedConfig: string) => void;
};

const LineChartConfigForm: FC<LineChartConfigFormProps> = ({
  defaultValues,
  onChange,
}) => {
  const { control, getValues } = useForm<ConfigLineChartConfig>({ defaultValues });

  const updateConfig = () => {
    const config = getValues();
    onChange(JSON.stringify(config));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <Controller
        name='xLabel.value'
        control={control}
        render={({ field }) => (
          <IntlTextInputLine
            label='X-Axis Label'
            placeholder='Enter X-Axis Label'
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateConfig();
            }}
          />
        )}
      />
      <Controller
        name='yLabel.value'
        control={control}
        render={({ field }) => (
          <IntlTextInputLine
            label='Y-Axis Label'
            placeholder='Enter Y-Axis Label'
            {...field}
            onChange={(e) => {
              field.onChange(e);
              updateConfig();
            }}
          />
        )}
      />
    </div>
  );
};

export default LineChartConfigForm;
